<template>
  <div class="container relative mx-auto py-4 pb-16 sm:py-16">
    <transition
      enter-active-class="miniFadeInTop"
      leave-active-class="miniFadeOutBottom"
      mode="out-in"
    >
      <router-view name="page" :plan="plan" class="relative z-10" />
    </transition>
  </div>
</template>

<script>
export default {
  props: ["plan"],
  computed: {
    background: function () {
      return this.$route.meta.background;
    },
    styleObject: function () {
      return "transform: rotate(" + (this.background.rotate || 0) + "deg)";
    },
  },
};
</script>

<style lang="scss" scoped>
.blob-container {
  width: 320px;
}

.rotate-12 {
}
</style>
